import * as React from "react";

const FONT_FAMILY_PRIMARY = `Arvo, sans-serif`;
const FONT_FAMILY_SECONDARY = `Montserrat, sans-serif`;

const FONT_WEIGHT_LIGHT = 400;
const FONT_WEIGHT_NORMAL = 600;
const FONT_WEIGHT_BOLD = 700;

export const Typography = {
  H1: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h1 style={style} ref={ref}>
        {children}
      </h1>
    )
  ),
  H2: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h2
        style={{
          fontSize: 36,
          fontFamily: FONT_FAMILY_PRIMARY,
          fontWeight: FONT_WEIGHT_LIGHT,
          ...style
        }}
        ref={ref}>
        {children}
      </h2>
    )
  ),
  H3: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h3 style={{
        fontSize: 24,
        fontFamily: FONT_FAMILY_PRIMARY,
        fontWeight: FONT_WEIGHT_LIGHT,
        ...style
      }} ref={ref}>
        {children}
      </h3>
    )
  ),
  H4: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h4 style={style} ref={ref}>
        {children}
      </h4>
    )
  ),
  H5: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h5 style={style} ref={ref}>
        {children}
      </h5>
    )
  ),
  H6: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h6 style={{
        fontSize: 20,
        fontFamily: FONT_FAMILY_SECONDARY,
        fontWeight: FONT_WEIGHT_LIGHT,
        ...style
      }} ref={ref}>
        {children}
      </h6>
    )
  ),
  P: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontSize: 15,
          fontFamily: FONT_FAMILY_PRIMARY,
          fontWeight: FONT_WEIGHT_LIGHT,
          ...style
        }}
        ref={ref}
        className="p">
        {children}
      </div>
    )
  ),
  LIGHT_LINK: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontSize: 15,
          fontFamily: FONT_FAMILY_SECONDARY,
          fontWeight: 300,
          ...style
        }}
        ref={ref}>
        {children}
      </div>
    )
  ),
  EYEBROW: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontSize: 13,
          fontFamily: FONT_FAMILY_SECONDARY,
          fontWeight: FONT_WEIGHT_BOLD,
          ...style
        }}
        ref={ref}>
        {children}
      </div>
    )
  ),
  BLOCKQUOTE: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLQuoteElement>
    ) => (
      <blockquote style={{
        fontSize: 16,
        fontFamily: FONT_FAMILY_SECONDARY,
        fontWeight: FONT_WEIGHT_LIGHT,
        ...style
      }} ref={ref}>
        {children}
      </blockquote>
    )
  )
};
