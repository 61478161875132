import Color from "ts-color-class";

export const colors = {
  primary: new Color("#001547"),
  secondary: new Color("#00AFD8"),
  white: new Color("#fefefe"),
  black: new Color("#3a3a3a"),
  green: new Color("#7AB800"),
  charcoal: new Color("#394A58"),
  yellow: new Color("#FFAE00"),
  gray: new Color("#A5AAA3"),
  lightBlue: new Color("#F4F9FD"),
  orange: new Color("#E9571C"),
  bodyText: new Color("#717685"),
  eyebrowYellow: new Color("#FEA905"),
  darkBlue: new Color("#001743"),
};

