import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Copyright } from "wmk-layout";
import { WmkLink } from "wmk-link";
import { LinkQuery } from "../../fragments/NodeLinkFields";
import { SiteMetaDataFields } from "../../fragments/NodeSiteMetadataFields";
import TextButton from "../Buttons/TextButton";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";

const FooterWrap = styled(Container)<{ width?: boolean; path: boolean }>`
  padding: 5rem 0 5rem;
  background: ${colors.lightBlue.getHex()};
  ${({ width, path }) =>
    width && path
      ? `position: relative;
  left: -12.5%;
  width: 75%;`
      : ""};
  .footer-icons {
    padding: 1rem 0;
    .logo a {
      text-align: center;
      display: block;
    }
    img {
      width: 240px;
    }
    justify-content: space-around;
  }
  .col {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .p {
    padding: 0 20vw;
  }
  .row {
    width: 100%;
  }
  @media screen and (max-width: 545px) {
    .p {
      padding: 0 5vw;
    }
  }
}`;

const Footer = ({ is404 }: { is404?: boolean }) => {
  const [viewWidth, setViewWidth] = useState(0);
  const [path, setPath] = useState("");

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const data: FooterQuery = useStaticQuery(query);

  useEffect(() => {
    const path = location?.pathname ? location.pathname : "";
    setPath(path);
  });

  const link = data.footer.link;
  return (
    <FooterWrap
      fluid
      width={viewWidth > 991 ? "true" : ""}
      path={
        is404
          ? ""
          : path.includes("kp-hdd-survey") || path.includes("kp-di-vs-pvc-survey")
          ? ""
          : path !== "/" && !path.includes("/pillars")
          ? "true"
          : ""
      }>
      <Row>
        <Col className="text-column">
          <Typography.BLOCKQUOTE
            style={{
              fontFamily: "Montserrat",
              color: colors.orange.getHex(),
              margin: "2rem 0 0.75rem",
              textTransform: "uppercase",
              fontWeight: 700,
              fontSize: "14px"
            }}>
            {data?.footer?.subheader}
          </Typography.BLOCKQUOTE>
        </Col>
      </Row>
      <Row>
        <Col className="text-column">
          <Typography.H2
            style={{
              fontFamily: "Arvo, Montserrat",
              fontWeight: 400,
              paddingBottom: "1rem"
            }}>
            {data?.footer?.header}
          </Typography.H2>
        </Col>
      </Row>
      <Row>
        <Col className="text-column">
          <Typography.P
            style={{ color: colors.bodyText.getHex(), paddingBottom: "2rem" }}>
            {data?.footer?.description}
          </Typography.P>
        </Col>
      </Row>
      <Row>
        <Col className="text-column">
          <TextButton to={`${link.path}`} arrow>
            {link.linkText}
          </TextButton>
        </Col>
      </Row>
    </FooterWrap>
  );
};

export default Footer;

interface FooterQuery {
  site: SiteMetaDataFields;
  footer: {
    header: string;
    subheader: string;
    description: string;
    link: LinkQuery;
  };
}

const query = graphql`
  {
    site {
      ...NodeSiteMetadata
    }
    footer: contentfulFooter {
      header
      subheader
      description
      link {
        ...NodeLinkFields
      }
    }
  }
`;
