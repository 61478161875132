import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";

const StyledButton = styled.div`
  text-align: center;
  a {
    padding: 0.5rem 4rem;
    width: fit-content;
    display: flex;
    border: 2px solid ${colors.yellow.getHex()};
    background: ${colors.yellow.getHex()};
    text-transform: uppercase;
    transform: scale(1);
    transition: all 0.3s ease;
    text-decoration: none;
    color: ${colors.charcoal.getHex()};
    font-weight: 700;

    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease;
      background: white;
      color: ${colors.charcoal.getHex()};
      border: 2px solid ${colors.yellow.getHex()};
    }
    &:active,
    &:focus {
      border: 2px solid ${colors.yellow.getHex()};
      background: none;
      transition: all 0.3s ease;
    }
  }
`;

const MainButton = ({
  to,
  target,
  children
}: {
  to: string;
  target?:
    | "_blank"
    | "blank"
    | "_parent"
    | "parent"
    | "_self"
    | "self"
    | "_top"
    | "top";
  children: React.ReactNode;
}) => {
  return (
    <StyledButton>
      <Typography.BLOCKQUOTE>
        <WmkLink to={to} target={target ? target : undefined}>
          {children}
        </WmkLink>
      </Typography.BLOCKQUOTE>
    </StyledButton>
  );
};

export default MainButton;
