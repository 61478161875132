import { WmkLink } from "wmk-link";
import React, { useState, useEffect } from "react";
import { colors } from "../ui/colors";
import { graphql, useStaticQuery } from "gatsby";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { HeaderQuery } from "../../fragments/NodeHeaderFields";
import ReactMarkdown from "react-markdown";
import MainButton from "../Buttons/MainButton";

const HeaderContainer = styled(Container)<{
  width: number;
  videodisplay: boolean;
  homedisplay: boolean;
  gallerydisplay: boolean;
}>`
  padding: 3rem 10vw;
  @media screen and (max-width: 992px) {
    padding: 1rem 8vw;
  }
  .copy-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 10% 1rem;
    color: ${colors.bodyText.getHex()};
    font-family: arvo;
    font-size: 15px;
  }
  .link-col {
    display: flex;
    justify-content: center;
  }

  ${({ videodisplay, width, gallerydisplay, homedisplay }) =>
    videodisplay && width > 992
      ? `position: relative;
      left: -12.5%;
      width: 75%;`
      : gallerydisplay && width > 767
      ? `.row {
        justify-content: end;
      }
      .dipra-col {
        display: flex;
        justify-content: flex-end;
      }
      .knowledge-col {
        display: flex;
        justify-content: center;
      }
      `
      : gallerydisplay
      ? `.row {
        justify-content: space-between;
      }
      .dipra-col {
        display: flex;
        justify-content: flex-end;
        border-left: 2px solid black;
      }
      .knowledge-col {
        display: flex;
        justify-content: flex-start;
      }`
      : homedisplay && width > 991
      ? `.row {
        justify-content: space-between;
      }
      .dipra-col {
        display: flex;
        justify-content: flex-end;
      }`
      : homedisplay
      ? `.row {
        justify-content: center;
        padding-bottom: 3rem;
      }`
      : ""};
`;

const Header = () => {
  const [viewWidth, setViewWidth] = useState(0);
  const [path, setPath] = useState("");

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  useEffect(() => {
    const path = location?.pathname ? location.pathname : "";
    setPath(path);
  });

  const data: { header: HeaderQuery } = useStaticQuery(headerQuery);
  // console.log("data", data);

  const isVideoDisplay = path && path.includes("/video") ? "true" : "";
  const isGalleryDisplay = path && path.includes("/pillar") ? "true" : "";
  const isHomePageDisplay = path && path === "/" ? "true" : "";
  // console.log("path", path);
  // console.log("isVideoDisplay", isVideoDisplay);

  const dipraLogo = new Img(data.header.secondaryLogo);
  const knowledgePipelineLogo = new Img(data.header.mainLogo);

  return (
    <HeaderContainer
      fluid
      width={viewWidth}
      videodisplay={isVideoDisplay}
      gallerydisplay={isGalleryDisplay}
      homedisplay={isHomePageDisplay}>
      <Row>
        <Col md={4} xs={7} sm={5} className="knowledge-col">
          <WmkLink to="/">
            <WmkImage image={knowledgePipelineLogo} />{" "}
          </WmkLink>
        </Col>
        {((isHomePageDisplay && viewWidth > 991) || isGalleryDisplay) && (
          <Col md={4} xs={5} className="dipra-col">
            <a href="https://dipra.org/">
              <WmkImage image={dipraLogo} />
            </a>
          </Col>
        )}
      </Row>
      {isGalleryDisplay && (
        <>
          {data.header.content?.content && (
            <Row>
              <Col className="copy-col">
                <ReactMarkdown>{data.header.content.content}</ReactMarkdown>
              </Col>
            </Row>
          )}
          {data.header.link && (
            <Row>
              <Col className="link-col">
                <MainButton to={data.header.link.path}>
                  {data.header.link.linkText}
                </MainButton>
              </Col>
            </Row>
          )}
        </>
      )}
    </HeaderContainer>
  );
};

// interface HeaderQuery {
//   dipraLogo: ContentfulImageQuery;
//   knowledgePipelineLogo: ContentfulImageQuery;
// }

export default Header;

const headerQuery = graphql`
  {
    header: contentfulHeader {
      ...NodeHeaderFields
    }
  }
`;
