import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";

const StyledButton = styled.div<{ underline: boolean }>`
  a,
  a > * {
    font-size: 16px !important;
    text-transform: uppercase;
    color: ${colors.green.getHex()} !important;
    transition: all 0.3s ease;
    ${({ underline }) => (underline ? ` ` : `text-decoration: none;`)};
  }
  a:hover,
  a > *:hover {
    color: ${colors.charcoal.getHex()} !important;
    transition: all 0.3s ease;
  }
`;

const TextButton = ({
  to,
  target,
  children,
  arrow
}: {
  to: string;
  target?:
    | "_blank"
    | "blank"
    | "_parent"
    | "parent"
    | "_self"
    | "self"
    | "_top"
    | "top";
  children: React.ReactNode;
  arrow?: boolean;
}) => {
  return (
    <StyledButton underline={arrow}>
      <Typography.BLOCKQUOTE style={{fontWeight: 700}}>
        <WmkLink to={to} target={target ? target : undefined}>
          {children}
          {arrow && " >"}
        </WmkLink>
      </Typography.BLOCKQUOTE>
    </StyledButton>
  );
};

export default TextButton;
