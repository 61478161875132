exports.components = {
  "component---src-node-gallery-tsx": () => import("./../../../src/node/gallery.tsx" /* webpackChunkName: "component---src-node-gallery-tsx" */),
  "component---src-node-video-tsx": () => import("./../../../src/node/video.tsx" /* webpackChunkName: "component---src-node-video-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kp-di-vs-pvc-survey-tsx": () => import("./../../../src/pages/kp-di-vs-pvc-survey.tsx" /* webpackChunkName: "component---src-pages-kp-di-vs-pvc-survey-tsx" */),
  "component---src-pages-kp-hdd-survey-tsx": () => import("./../../../src/pages/kp-hdd-survey.tsx" /* webpackChunkName: "component---src-pages-kp-hdd-survey-tsx" */),
  "component---src-pages-pillars-tsx": () => import("./../../../src/pages/pillars.tsx" /* webpackChunkName: "component---src-pages-pillars-tsx" */)
}

