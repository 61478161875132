import * as React from "react";
import { MainLayout, Sticky } from "wmk-layout";
import Header from "./Header";
import Footer from "./Footer";
import Helmet from "react-helmet";

/* Feed this to your header and/or footer once data is queried */
import { MenuData, HyperLink } from "wmk-menu";

const Layout = ({
  children
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <MainLayout
      Header={<Header />}
      Footer={
        children?.props?.pageResources?.page?.path.includes("404") ? (
          <Footer is404 />
        ) : (
          <Footer />
        )
      }>
      {/* <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TS3NJGL');`}
        </script>
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TS3NJGL"
          height="0"
          width="0"
          style={{display:"none",visibility:"hidden"}}></iframe>
      </noscript> */}
      {children}
    </MainLayout>
  );
};

export default Layout;
